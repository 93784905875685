import { useFetchOrgList } from '@/api/org/useFetchOrgList'
import { OrgEmptyState } from '@/features/Org/OrgEmptyState'
import { Outlet } from 'react-router-dom'

export const OrgRoutes = () => {
  const { data: userOrgs } = useFetchOrgList()

  if (userOrgs?.length === 0) {
    return <OrgEmptyState />
  }

  return <Outlet />
}
