import { useQuery, useQueryClient } from '@tanstack/react-query'

import { api } from 'instrument'
import { QUERY_KEYS } from '../queryKeys'

export const useFetchOrgList = () => {
  return useQuery({
    queryKey: [QUERY_KEYS.FETCH_ORG_LIST],
    queryFn: () => api.orgControllerListMine().then((res) => res.data.orgs),
    staleTime: Infinity, // Only refetch if query is manually invalidated or page is refreshed
  })
}

export const useRefreshOrgList = () => {
  const queryClient = useQueryClient()

  return async () => {
    return await queryClient.invalidateQueries({
      queryKey: [QUERY_KEYS.FETCH_ORG_LIST],
    })
  }
}
