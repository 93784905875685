import { datadogRum } from '@datadog/browser-rum'
import { ApiLinkedStore, api } from 'instrument'
import { makeAutoObservable } from 'mobx'
import posthog from 'posthog-js'

class AuthStore {
  accessToken = new ApiLinkedStore(() => api.thirdPartyControllerRefresh())

  // using a separate api linked store for refetching the access token, since we don't have option to keep
  // the existing data while refetching in api linked store
  private refetchApi = new ApiLinkedStore(() =>
    api.thirdPartyControllerRefresh(),
  )

  constructor() {
    makeAutoObservable(this)
  }

  fetchAccessToken() {
    return this.accessToken.fetch()
  }

  refetchAccessToken = async () => {
    const res = await this.refetchApi.fetch()

    if (res.apiCallStatus === 'success') {
      this.accessToken.setStoreState({
        apiCallStatus: 'success',
        data: res.data,
      })
    } else if (res.apiCallStatus === 'error') {
      throw res.error
    }
  }

  isLoggedIn = () => {
    return this.accessToken.state.apiCallStatus === 'success'
  }

  logout = async () => {
    await api.authControllerLogout()
    posthog.reset()
    datadogRum.clearGlobalContext()
    datadogRum.clearUser()
    datadogRum.stopSessionReplayRecording()
    location.reload()
  }
}

export default new AuthStore()
