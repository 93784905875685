import { makeAutoObservable } from 'mobx'
import {
  ACTIVE_NAMESPACE_KEY,
  NamespaceType,
  type Namespace,
} from '../../features/Org/types/namespace'

class NamespaceStore {
  private _activeNamespace: Namespace | null = null

  constructor() {
    const namespaceInPersistentStorage = this.getActiveNamespaceFromStorage()

    if (namespaceInPersistentStorage) {
      this.activeNamespace = namespaceInPersistentStorage
    }

    makeAutoObservable(this)
  }

  get activeNamespace() {
    return this._activeNamespace
  }

  set activeNamespace(namespace: Namespace | null) {
    this._activeNamespace = namespace
    this.setActiveNamespaceInStorage(namespace)
  }

  setActiveOrg(orgSlug: string) {
    this.activeNamespace = {
      type: NamespaceType.ORG,
      slug: orgSlug,
    }
  }

  private getActiveNamespaceFromStorage(): Namespace | null {
    const activeNamespaceString = localStorage.getItem(ACTIVE_NAMESPACE_KEY)
    if (!activeNamespaceString) return null

    return JSON.parse(activeNamespaceString)
  }

  private setActiveNamespaceInStorage(namespace: Namespace | null) {
    localStorage.setItem(ACTIVE_NAMESPACE_KEY, JSON.stringify(namespace))
  }
}

export const namespaceStore = new NamespaceStore()
