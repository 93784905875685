import _ from 'lodash'
import classes from './TetrisLoader.module.css'

const TetrisLoader = () => {
  const loadingStrings = [
    'Forging the fantastic',
    'Assembling the Awesome',
    'Building the Brilliant',
  ]
  return (
    <div className="flex items-center gap-2 text-black">
      <div className={`${classes['loader']} h-[16px] w-[24px]`}></div>
      <div>{_.sample(loadingStrings)}</div>
    </div>
  )
}

export default TetrisLoader
