export const ACTIVE_NAMESPACE_KEY = 'activeNamespace'

export enum NamespaceType {
  ORG = 'org',
  EXTERNAL = 'external',
}

export type Namespace =
  | {
      type: NamespaceType.EXTERNAL
    }
  | {
      type: NamespaceType.ORG
      slug: string
    }
