import clsx from '@/lib/utils/clsx'
import TetrisLoader from '../TetrisLoader'

export const LoadingArea = ({ className }: { className?: string }) => {
  return (
    <div
      className={clsx(
        'flex h-full w-full items-center justify-center',
        className,
      )}
    >
      <TetrisLoader />
    </div>
  )
}
