import { LoadingArea } from '@/components/LoadingArea'
import React, { type ComponentType, lazy as reactLazy, Suspense } from 'react'

type LazyComponentModule = { default: ComponentType<any> }
type LazyLoadCallback = () => Promise<LazyComponentModule>

/**
 * Enhanced lazy loading wrapper for React components.
 *
 * This function provides a convenient way to lazy-load React components with built-in
 * error handling and Suspense integration.
 *
 * @param importCallback - A function that returns a promise resolving to a module with a default export of a React component.
 * @param fallback - Optional React node to render while the component is loading.
 * @returns A function component that renders the lazy-loaded component wrapped in a Suspense.
 *
 * @example
 * const LazyComponent = lazy(() => import('./MyComponent'), <LoadingSpinner />);
 * // Usage: <LazyComponent />
 */
const lazy = (importCallback: LazyLoadCallback, fallback?: React.ReactNode) => {
  const LazyComponent = reactLazy(() =>
    importCallback().catch(() => {
      if (import.meta.env.MODE !== 'mylocal') {
        window.location.reload()
      }
      return { default: () => null }
    }),
  )

  return () => (
    <Suspense
      fallback={fallback ?? <LoadingArea className="h-screen w-screen" />}
    >
      <LazyComponent />
    </Suspense>
  )
}

export { lazy }
